/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Zanechat vzkaz"}>
        <Column className="css-123vcn5 --parallax pb--80 pt--80" name={"wstęp"} style={{"minHeight":"100vh"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 pb--60 pl--40 pr--40 pt--80" anim={"2"} animS={"2"} style={{"maxWidth":800,"backgroundColor":"rgba(248, 244, 244, 1)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box swpf--uppercase" content={"Zostaw wiadomość"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <ContactForm className="--shape2 --style1 ff--2 w--300" style={{"maxWidth":520}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Wiadomość","type":"textarea","required":true,"placeholder":"Wpisz tekst wiadomości"},{"name":"Wyślij","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

            <ColumnWrapper className="mt--30">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 swpf--uppercase" innerClassName="pl--0 pr--0" href={"/pl/"} content={"Wstecz"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}